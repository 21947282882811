import { useQuery } from 'villus';
import { type CategoryListQuery } from '~/graphql/Category';
import type { OfferCardFragment } from '~/graphql/fragments';
import { HomeDocument } from '~/graphql/Home';
import type { Unpacked } from '~/types/utils';

export type Category = Omit<NonNullable<Unpacked<CategoryListQuery['categoryList']>>, 'children'> & {
  children?: Category[];
  meta_title?: string;
  meta_description?: string;
  meta_keywords?: string;
  offer?: OfferCardFragment[];
  level: number | undefined;
};

type MappedHomeOffers = Record<string | number, OfferCardFragment> | undefined;

type HomeData = {
  offers: OfferCardFragment[];

  topDeals: ProductNode[];

  latestProducts: ProductNode[];

  mappedHomeOffers: MappedHomeOffers;
};

export async function useHomeItems(): Promise<HomeData> {
  const { cacheParam } = useSetLocaleToCacheParams();

  const index = useCachedSsrRef<HomeData | undefined>(
    cacheParam('index'),
    undefined,
    /* ttl = 30 min */ 60 * 1000 * 5,
  ) as Ref<HomeData | undefined>;

  const homeOffers = ref<OfferCardFragment[] | []>(index?.value?.offers || []);

  const topDeals = ref<ProductNode[]>(index?.value?.topDeals || []);

  const latestProducts = ref<ProductNode[]>(index?.value?.latestProducts || []);

  const mappedHomeOffers = ref<MappedHomeOffers>(index?.value?.mappedHomeOffers || {});

  if (index?.value?.offers || index.value?.topDeals || index.value?.latestProducts || index.value?.mappedHomeOffers) {
    return {
      offers: homeOffers.value,
      topDeals: topDeals.value,
      latestProducts: latestProducts.value,
      mappedHomeOffers: mappedHomeOffers.value,
    };
  }

  const { execute } = useQuery({
    query: HomeDocument.toString(),
    fetchOnMount: false,
  });

  const { data, error } = await execute({
    variables: {
      limit: 12,
    },
  });

  if (error) {
    throw new Error(error?.message);
  }

  index.value = {
    offers: toNonNullable(data?.homeOffers)?.sort((a, b) => (a?.sort || 0) - (b?.sort || 0)) || [],
    topDeals: appProductsMapper(toNonNullable(data?.topDeals?.items) || []),
    latestProducts: appProductsMapper(toNonNullable(data?.latestProducts?.items) || []),
    mappedHomeOffers: data?.homeOffers
      .filter(offer => offer && offer.sort)
      .reduce<MappedHomeOffers>((accu, offer) => {
        return {
          ...accu,
          [(offer?.sort || 0).toString()]: offer as OfferCardFragment,
        };
      }, {} as MappedHomeOffers),
  };

  homeOffers.value = index.value?.offers || [];
  topDeals.value = index.value?.topDeals || [];
  latestProducts.value = index.value?.latestProducts || [];
  mappedHomeOffers.value = index.value?.mappedHomeOffers;

  return {
    offers: homeOffers.value,
    topDeals: topDeals.value,
    latestProducts: latestProducts.value,
    mappedHomeOffers: mappedHomeOffers.value,
  };
}
